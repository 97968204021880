import { defineComponent, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Toast } from 'vant';
import { copyToClipboard } from '../../utils/index';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var store = useStore();
    var jobDetailInfo = computed(function () {
      return store.state.PositionStore.jobInfo;
    });
    var jid = route.query.jid;

    var applyPosition = function applyPosition() {
      var jobDetailUrl = "https://job.iguopin.com/job/detail?id=".concat(jid, "&source=campus");
      copyToClipboard(jobDetailUrl);
      Toast.success('投递地址剪切成功');
    };

    onMounted(function () {
      store.dispatch('fetchJobInfo', {
        urlParams: {
          id: jid
        }
      });
    });
    return {
      jobDetailInfo: jobDetailInfo,
      applyPosition: applyPosition
    };
  }
});