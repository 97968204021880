import _objectSpread from "D:/ProjectCode/JS/VUE/v3_ts_template/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Toast } from 'vant';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var store = useStore();
    var inputValue = ref('');
    var moreCount = ref(1);
    var showMore = ref(true);
    var jobList = computed(function () {
      return store.state.PositionStore.jobList;
    });
    var jobCount = computed(function () {
      return store.state.PositionStore.jobCount;
    });
    var indexBarVal = computed(function () {
      return store.state.PositionStore.indexBarSelectVal;
    });

    var onClickButton = function onClickButton() {
      if (inputValue.value) {
        store.commit('setIndexBarSelectVal', [{
          subject: inputValue.value
        }, {
          city: indexBarVal.value.city
        }]);
        getJobList({
          page: 1,
          limit: 10,
          city: indexBarVal.value.city,
          subject: inputValue.value
        });
      } else {
        Toast.fail('请输入专业');
      }
    };

    var jumpJobDetailPage = function jumpJobDetailPage(val) {
      router.push({
        path: '/position/detail',
        query: {
          jid: val
        }
      });
    };

    var clickCityJump = function clickCityJump() {
      router.push({
        path: '/position/bar',
        query: {
          status: 'city'
        }
      });
    };

    var getJobList = function getJobList(args) {
      store.dispatch('fetchJobList', {
        searchParams: _objectSpread({}, args)
      });
    };

    var loadMoreJob = function loadMoreJob() {
      moreCount.value += 1;
      var totalPage = Math.ceil(jobCount.value / 10);

      if (moreCount.value <= totalPage) {
        var _indexBarVal$value = indexBarVal.value,
            subject = _indexBarVal$value.subject,
            city = _indexBarVal$value.city;
        store.dispatch('fetchJobMoreList', {
          searchParams: {
            page: moreCount.value,
            limit: 10,
            city: city,
            subject: subject
          }
        });
      } else {
        showMore.value = false;
        Toast.fail('没有更多了');
      }
    };

    onMounted(function () {
      var _indexBarVal$value2 = indexBarVal.value,
          subject = _indexBarVal$value2.subject,
          city = _indexBarVal$value2.city;
      getJobList({
        page: 1,
        limit: 10,
        city: city,
        subject: subject
      });
    });
    return {
      indexBarVal: indexBarVal,
      inputValue: inputValue,
      jobList: jobList,
      showMore: showMore,
      onClickButton: onClickButton,
      clickCityJump: clickCityJump,
      jumpJobDetailPage: jumpJobDetailPage,
      loadMoreJob: loadMoreJob
    };
  }
});