import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { CITY, SUBJECT } from '../../assets/js/data';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var title = ref('');
    var items = ref();
    var statu = ref('');
    var status = route.query.status;

    var clickCell = function clickCell(val) {
      store.commit('setIndexBarSelectVal', [status, val]);
      router.back();
    };

    onMounted(function () {
      statu.value = status;
      title.value = status === 'city' ? '请选择城市' : '请选择专业';
      items.value = status === 'city' ? CITY : SUBJECT;
    });
    return {
      statu: statu,
      title: title,
      items: items,
      indexList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      clickCell: clickCell
    };
  }
});