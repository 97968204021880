import { defineComponent } from 'vue';
export default defineComponent({
  setup: function setup() {
    var onSubmit = function onSubmit() {
      console.log('下单');
    };

    return {
      onSubmit: onSubmit
    };
  }
});