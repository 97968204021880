import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_nav_bar = _resolveComponent("van-nav-bar");

  var _component_van_index_anchor = _resolveComponent("van-index-anchor");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_index_bar = _resolveComponent("van-index-bar");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: _ctx.title
  }, null, 8, ["title"]), _createVNode(_component_van_index_bar, {
    "index-list": _ctx.indexList
  }, {
    default: _withCtx(function () {
      return [_ctx.statu === 'city' ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(_ctx.items, function (item, index) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: 'city-' + index
        }, [item[0].name !== '全国' ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(item, function (i, idx) {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: 'i-' + idx
          }, [idx === 0 ? (_openBlock(), _createBlock(_component_van_index_anchor, {
            key: 0,
            index: i.index,
            style: {
              "background-color": "#f5f6f7"
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(i.index), 1)];
            }),
            _: 2
          }, 1032, ["index"])) : _createCommentVNode("", true), _createVNode(_component_van_cell, {
            title: i.name,
            onClick: function onClick($event) {
              return _ctx.clickCell(i.name);
            }
          }, null, 8, ["title", "onClick"])], 64);
        }), 128)) : (_openBlock(), _createBlock(_component_van_cell, {
          key: 1,
          title: item[0].name,
          onClick: function onClick($event) {
            return _ctx.clickCell(item[0].name);
          }
        }, null, 8, ["title", "onClick"]))], 64);
      }), 128)) : _createCommentVNode("", true), _ctx.statu === 'subject' ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(_ctx.items, function (item, ids) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: 'sub-' + ids
        }, [_createVNode(_component_van_index_anchor, {
          index: item.index,
          style: {
            "background-color": "#f5f6f7"
          }
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(item.text), 1)];
          }),
          _: 2
        }, 1032, ["index"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, function (ch, idc) {
          return _openBlock(), _createBlock(_component_van_cell, {
            key: 'ch-' + idc,
            title: ch.text,
            onClick: function onClick($event) {
              return _ctx.clickCell(ch.text);
            }
          }, null, 8, ["title", "onClick"]);
        }), 128))], 64);
      }), 128)) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["index-list"])], 64);
}