import { defineComponent, ref, watchEffect, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var store = useStore();
    var subject = ref('');
    var city = ref('');
    var btnDisabled = ref(true);
    var indexBarVal = computed(function () {
      return store.state.PositionStore.indexBarSelectVal;
    });
    subject.value = indexBarVal.value.subject;
    city.value = indexBarVal.value.city;
    watchEffect(function () {
      if (subject.value && city.value) {
        btnDisabled.value = false;
      } else {
        btnDisabled.value = true;
      }
    });

    var onSubmit = function onSubmit() {
      store.commit('setIndexBarSelectVal', [{
        subject: subject.value
      }, {
        city: city.value
      }]);
      router.push({
        path: '/position/index',
        query: {
          subject: subject.value,
          city: city.value
        }
      });
    };

    var clickSubBtn = function clickSubBtn(val) {
      router.push({
        path: '/position/bar',
        query: {
          status: val
        }
      });
    };

    return {
      subject: subject,
      city: city,
      btnDisabled: btnDisabled,
      indexBarVal: indexBarVal,
      onSubmit: onSubmit,
      clickSubBtn: clickSubBtn
    };
  }
});