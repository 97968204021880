import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e3685e2e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "job-list job-list-new"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  href: "javascript:void(0);"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  class: "title-text"
};
var _hoisted_6 = {
  class: "salary"
};
var _hoisted_7 = {
  class: "name"
};
var _hoisted_8 = {
  class: "company"
};
var _hoisted_9 = {
  class: "workplace"
};
var _hoisted_10 = {
  class: "workplace"
};
var _hoisted_11 = {
  class: "labels"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_search = _resolveComponent("van-search");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_search, {
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.inputValue = $event;
    }),
    "show-action": "",
    placeholder: "请输入专业"
  }, {
    label: _withCtx(function () {
      return [_createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = //@ts-ignore
        function () {
          return _ctx.clickCityJump && _ctx.clickCityJump.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.indexBarVal.city), 1)];
    }),
    action: _withCtx(function () {
      return [_createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = //@ts-ignore
        function () {
          return _ctx.onClickButton && _ctx.onClickButton.apply(_ctx, arguments);
        })
      }, "搜索")];
    }),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: 'job-' + index,
      class: "item",
      onClick: function onClick($event) {
        return _ctx.jumpJobDetailPage(item.id);
      }
    }, [_createElementVNode("a", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(item.jobs_name), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(item.wage_cn), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(item.department), 1), _createElementVNode("span", _hoisted_9, _toDisplayString(item.district_cn.split('/')[0]), 1), _createElementVNode("span", _hoisted_10, "报名截止：" + _toDisplayString(item.end_time_text.split(' ')[0]), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(item.experience_cn), 1), _createElementVNode("span", null, _toDisplayString(item.education_cn), 1), _createElementVNode("span", null, _toDisplayString(item.nature_cn), 1), _createElementVNode("span", null, _toDisplayString(item.major_cn), 1), _createElementVNode("span", null, _toDisplayString(item.category_cn), 1)])])], 8, _hoisted_2);
  }), 128))]), _withDirectives(_createElementVNode("div", {
    class: "loadmore",
    "data-url": "/wapi/zpgeek/mobile/search/joblist.json?city=101250100&querySource=1",
    ka: "more-jobs",
    "data-page": "1",
    "data-hasmore": "true",
    onClick: _cache[3] || (_cache[3] = //@ts-ignore
    function () {
      return _ctx.loadMoreJob && _ctx.loadMoreJob.apply(_ctx, arguments);
    })
  }, "加载更多", 512), [[_vShow, _ctx.showMore]])])], 64);
}